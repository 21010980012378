import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tooltip",
      "style": {
        "position": "relative"
      }
    }}>{`Tooltip`}</h1>
    <p>{`Tooltips provide additional information upon hover or focus. They often contain helper text that is contextual to an element. Tooltips do not receive input focus.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`They should contain read-only text. The use of interactive elements, such as Buttons or Links is discouraged.`}</li>
      <li parentName="ul">{`The position must be flexible and change depending on how close the element is to the edge of the screen.`}</li>
    </ul>
    <h2 {...{
      "id": "examples",
      "style": {
        "position": "relative"
      }
    }}>{`Examples`}</h2>
    <Demo src='pages/components/tooltip/TooltipDefault' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      